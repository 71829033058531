<script>
  import Layout from "./Default.svelte";
  import Logo from "@/Components/Assets/Logo.svelte";
  import Nav from "@/Components/Layout/Nav.svelte";
  import {page} from "@inertiajs/svelte";
  import {IconFacebook, IconLinkedIn, IconTwitter} from "@/Components/Assets/Icons/index.js";
  import Icon from "@/Components/Assets/Icon.svelte";

  export let nav = false;
  export let footer = false;
</script>

<Layout>
  <a href="#main-content" class="skip">Skip to main content</a>

  <div class="wrapper">
    <header>
      <div>
        <div class="logo">
          <Logo theme="color" href={$page.props?.auth.loggedIn ? '/app/respond' : '/'} />
        </div>

        {#if nav}
          <Nav />
        {/if}
      </div>
    </header>

    <main id="main-content">
      <article class={$$props.class}>
        <slot />
      </article>
    </main>

    {#if footer}
      <footer>
        <div>
          <div class="cols">
            <div>
              <div class="logo-wrap">
                <Logo class="logo" type="text" theme="light" />

                <div class="social">
                  <a href="https://www.linkedin.com/company/pitchfire" target="_blank" rel="noopener noreferrer">
                    <Icon data={IconLinkedIn} size="xxl" class="icon fill-white hover:fill-red transition duration-300 ease-in-out" />
                  </a>
                  <a href="https://www.facebook.com/thepitchfire" target="_blank" rel="noopener noreferrer">
                    <Icon data={IconFacebook} size="xxl" class="icon fill-white hover:fill-red transition duration-300 ease-in-out" />
                  </a>
                  <a href="https://twitter.com/thepitchfire" target="_blank" rel="noopener noreferrer">
                    <Icon data={IconTwitter} size="xxl" class="icon fill-white hover:fill-red transition duration-300 ease-in-out" />
                  </a>
                </div>
              </div>
            </div>

            <nav>
              <ul>
                <li><a href="/">Sign Up</a></li>
                <li><a href="https://meetings.hubspot.com/jeremy-leveille" target="_blank" rel="noopener noreferrer">Contact Sales</a></li>
                <li><a href="https://workspace.google.com/marketplace/app/pitchfire/126639686392" target="_blank" rel="noopener noreferrer">Gmail Plugin</a></li>
                <li><a href="https://appsource.microsoft.com/en-us/product/office/WA200006327" target="_blank" rel="noopener noreferrer">Outlook Plugin</a></li>
                <li><a href="https://pitchfire.com/blog">Blog</a></li>
              </ul>
            </nav>

            <nav>
              <ul>
                <li><a href="mailto:support@pitchfire.com" target="_blank" rel="noopener noreferrer">Support</a></li>
                <li><a href="https://www.pitchfire.com/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a></li>
                <li><a href="https://www.pitchfire.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
              </ul>
            </nav>
          </div>

          <p class="text-center text-gray-500">© {new Date().getFullYear()} Request for Meeting dba Pitchfire. All rights reserved.</p>
        </div>
      </footer>
    {/if}
  </div>
</Layout>

<style lang="postcss">
  .skip {
    @apply sr-only absolute top-0 left-0 focus:not-sr-only;
  }

  .wrapper {
    @apply min-h-screen flex flex-col;
    background: linear-gradient(180deg, #FFF 0%, #F9F9FF 2%, #CECEDC 100%);
  }

  header {
    @apply py-8;

    & > div {
      @apply mx-auto container max-w-7xl px-4 flex relative z-20;
    }

    .logo {
      @apply w-40;
    }
  }

  main {
    @apply pb-24;
  }

  article {
    @apply mx-auto px-4 py-8 container h-full max-w-5xl;
  }

  footer {
    @apply bg-blue py-20 text-white mt-auto;

    > div {
      @apply mx-auto container max-w-4xl px-4 lg:px-0;
    }

    .cols {
      @apply flex justify-center md:justify-between mb-12 flex-col md:flex-row gap-12 md:gap-8;

      .social {
        @apply flex justify-between mt-8;

        a {
          @apply no-underline block;
        }
      }

      & > div,
      nav {
        @apply w-full md:w-1/3 text-center md:text-left;
      }

      .logo-wrap {
        @apply w-40 mx-auto md:mx-0;
      }

      nav {
        ul {
          @apply flex flex-col gap-y-2 m-0 p-0;

          li {
            @apply list-none m-0 p-0;

            a {
              @apply text-white no-underline hover:text-red transition duration-300 ease-in-out;
            }
          }
        }
      }
    }
  }
</style>
